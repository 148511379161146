<template>
  <section
    v-if="formData.dateIssued"
    class="invoice-preview-wrapper"
  >

    <b-row
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        v-show="!isLoading"
        id="printMe"
        ref="printMe"
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column mt-0">

              <!-- Header: Left Content -->
              <div class="d-flex">
                <div class="logo-wrapper">
                  <b-img
                    :src="require('@/assets/images/logo/tm-partial-logo-2.png')"
                    alt="logo"
                  />
                  <h3 class="text-primary invoice-logo ml-0">
                    {{ appName }}
                  </h3>
                </div>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  {{ formData.invoiceNo }}
                </h4>
                <div class="invoice-date-wrapper mb-0">
                  <p class="invoice-date-title">
                    Date Issued:
                  </p>
                  <p class="invoice-date">
                    {{ formData.dateIssued }}
                  </p>
                </div>
                <div
                  v-if="formData.dueDate"
                  class="invoice-date-wrapper"
                >
                  <p class="invoice-date-title">
                    Due Date:
                  </p>
                  <p class="invoice-date">
                    {{ formData.dueDate }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Client & Payment Details -->
          <b-card-body
            class="invoice-padding pt-0"
          >
            <div class="invoice-spacing d-flex justify-content-between">
              <!-- Col: Invoice To -->
              <div
                class="p-0"
              >
                <h6 class="mb-2">
                  From:
                </h6>
                <h6 class="mb-25 font-weight-bolder">
                  {{ formData.systemSettings.company_name }}
                </h6>
                <p class="card-text mb-25">
                  {{ formData.systemSettings.company_address1 }}
                </p>
                <p class="card-text mb-25">
                  {{ formData.systemSettings.company_address2 }}
                </p>
                <p class="card-text mb-25">
                  {{ formData.systemSettings.vatno }}
                </p>
              </div>

              <!-- Col: Payment Details -->
              <div
                class="p-0"
              >
                <h6 class="mb-2">
                  To:
                </h6>
                <h6 class="mb-25 font-weight-bolder">
                  {{ formData.client }}
                </h6>
                <p class="card-text mb-25 invoice-to-address">
                  {{ formData.address + ', ' + formData.country }}
                </p>
                <p class="card-text mb-25">
                  Tax-ID/VAT: {{ formData.tax }}
                </p>
              </div>
            </div>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-table-lite
            responsive
            :items="formData.invoiceData.items"
            :fields="['itemTitle', 'qty', 'unitPrice', 'itemPrice']"
          >

            <template #head(itemTitle)>
              <span>Item</span>
            </template>

            <template #head(unitPrice)>
              <span>Unit Price</span>
            </template>

            <template #head(itemPrice)>
              <span>Item Price</span>
            </template>

            <template #cell(itemTitle)="data">
              <b-card-text class="font-weight-bold">
                {{ data.value.text ? data.value.text : data.value }}
              </b-card-text>
            </template>

          </b-table-lite>

          <p
            v-if="formData.periodDate.startDate"
            class="w-100 invoice-period"
          >
            Period: {{ formData.periodDate.startDate + ' - ' + formData.periodDate.endDate }}
          </p>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>

              <!-- Col: Total -->
              <b-col
                cols="12"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Subtotal:
                    </p>
                    <p class="invoice-total-amount">
                      {{ formData.currency + ' ' + parseFloat(formData.subTotal).toFixed(2) }}
                    </p>
                  </div>
                  <div
                    v-if="formData.discountValue > 0"
                    class="invoice-total-item"
                  >
                    <p class="invoice-total-title">
                      Discount:
                    </p>
                    <p class="invoice-total-amount">
                      - {{ formData.currency + ' ' + parseFloat(formData.discountValue).toFixed(2) }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      VAT ({{ formData.vatPercentage }}%):
                    </p>
                    <p class="invoice-total-amount">
                      {{ formData.currency + ' ' + parseFloat(formData.vat).toFixed(2) }}
                    </p>
                  </div>
                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Total:
                    </p>
                    <p class="invoice-total-amount">
                      {{ formData.currency + ' ' + parseFloat(formData.totalToPay).toFixed(2) }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <span v-if="invoiceType === 'invoice'">
            <p
              v-if="invoiceDetails.version === 2 && invoiceDetails.currency !== 'RON'"
              class="text-right mt-1 mr-2 pr-1 mb-0"
            >
              Total in Lei (exchange rate BNR: {{ invoiceDetails.exchange_rate }} ): {{ parseFloat(invoiceDetails.exchange_rate * invoiceDetails.invoice_value).toFixed(2) }} RON
            </p>
          </span>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Note -->
          <b-card-body
            class="invoice-padding text-center pt-0"
          >
            <p v-if="formData.existingClients">
              Exempt by virtue of Art 7 of the Twelfth Schedule to the Value Added Tax Act - Chapter 406
            </p>
            <p v-else>
              Customer to account for any VAT arising on this supply in accordance with Article 196 Council Directive 2016/112/EC
            </p>
            <p
              v-if="payment && formData.invoice_status !== 'pending'"
              class="paid-style mt-1"
            >
              <span v-if="payment.paidWith === 'creditCard'">
                Paid by Credit Card on {{ payment.payment_date }}<br>Transaction Reference: {{ payment.payment + 'I' + payment.invoice }}
              </span>
              <span v-else-if="payment.paidWith === 'credit_card'">
                Paid by Credit Card on {{ payment.payment_date }}<br>Transaction Reference: {{ payment.ipc_trnref ? payment.ipc_trnref : invoiceDetails.invoice_number_v2 }}
              </span>
              <span v-else-if="payment.paidWith === 'payPal'">
                Paid by PayPal on {{ payment.payment_date }}<br>Transaction Reference: {{ payment.ipc_trnref }}
              </span>
              <span v-else-if="payment.paidWith === 'wire'">
                Paid by Wire Transfer on {{ payment.payment_date }}
              </span>
            </p>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <!-- Invoice Status -->
          <div
            class="mt-75 paid-style"
            block
          >
            <p v-if="formData.invoice_status==='pending'">
              <b class="text-warning">Invoice Status: {{ formData.invoice_status }} payment</b>
            </p>
            <p v-else-if="formData.invoice_status==='overdue'">
              <b class="text-danger">Invoice Status: {{ formData.invoice_status }}</b>
            </p>
            <p v-else>
              <b>Invoice Status: {{ formData.invoice_status == 6 ? 'Referral balance applied' : formData.invoice_status }} </b>
            </p>
          </div>

          <!-- Button: Edit -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-toggle.sidebar-send-invoice
            variant="primary"
            class="mb-75"
            block
            :disabled="downloading"
            @click="edit"
          >
            Edit
          </b-button>

          <!-- Button: Download -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            block
            @click="download"
          >
            <span v-if="downloading">
              <b-spinner
                small
                type="grow"
              />
              Downloading...
            </span>
            <span v-else>Download</span>
          </b-button>

          <!-- Button: View User -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            block
            @click="viewUser"
          >
            View User
          </b-button>

        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BButton, BImg, BCardText, VBToggle, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { $themeConfig } from '@themeConfig'
import axios from '@/libs/axios'
import moment from 'moment'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BButton,
    BImg,
    BCardText,
    BSpinner,
  },
  data() {
    return {
      isLoading: true,
      status: 'Paid',
      invoiceType: '',
      invoiceDetails: {},
      payment: null,
      items: {},
      appName: null,
      userId: 0,
      formData: {
        invoice_status: '',
        dateIssued: '',
        dueDate: '',
        invoiceTo: null,
        client: '',
        address: '',
        tax: '',
        country: '',
        subTotal: 0,
        totalToPay: 0,
        vat: 0,
        vatPercentage: 0,
        bankDetails: '',
        existingClients: false,
        systemSettings: null,
        invoiceNo: 0,
        currency: '',
        discountValue: 0,
        periodDate: {
          startDate: null,
          endDate: null,
        },
        invoiceData: {
          items: [],
        },
      },
      downloading: false,
      pdfFilename: '',
    }
  },
  created() {
    this.getInvoiceByID()
    this.appName = $themeConfig.app.appName
  },
  mounted() {},
  methods: {
    getInvoiceByID() {
      this.$store.dispatch('invoice/getCustomInvoiceByID', this.$route.params.id)
        .then(res => {
          this.payment = res.payment
          this.formData.invoice_status = res.invoice.invoice_status
          this.invoiceType = res.invoiceType
          this.invoiceDetails = res.invoice
          this.formData.systemSettings = res.systemSettings
          this.formData.invoiceNo = res.invoice.version === 1 ? `Invoice# ${res.invoice.invoiceNo}` : `Invoice#  TMC${res.invoice.invoice_number_v2.toString().padStart(4, '0')}`
          this.formData.dateIssued = moment(new Date(res.invoice.issueDate)).format('DD MMM YYYY')
          this.formData.dueDate = moment(new Date(res.invoice.dueDate)).format('DD MMM YYYY')
          this.formData.client = res.invoice.to
          this.formData.address = res.invoice.userAddress
          this.formData.country = res.billingCountry
          this.formData.tax = res.invoice.userVAT
          this.formData.subTotal = parseFloat(res.invoice.subtotal).toFixed(2)
          this.formData.totalToPay = parseFloat(res.invoice.total).toFixed(2)
          this.formData.vat = parseFloat(res.invoice.vatValue).toFixed(2)
          this.formData.vatPercentage = res.invoice.vatPercent
          this.formData.periodDate.startDate = res.invoice.periodStart
          this.formData.periodDate.endDate = res.invoice.periodEnd
          this.formData.currency = res.invoice.currency
          this.userId = res.invoice.uid
          this.pdfFilename = `${this.formData.invoiceNo} ${res.invoice.to}`

          if (res.invoice.package != null) {
            this.formData.invoiceData.items.push({
              itemTitle: res.invoice.packageName,
              qty: res.invoice.packageQty,
              unitPrice: `${res.invoice.currency} ${parseFloat(res.invoice.packageUnitPrice).toFixed(2)}`,
              itemPrice: `${res.invoice.currency} ${parseFloat(res.invoice.packageItemPrice).toFixed(2)}`,
            })
          }

          res.items.forEach(item => {
            this.formData.invoiceData.items.push({
              itemTitle: item.customProductName, qty: item.customQty, unitPrice: `${res.invoice.currency} ${parseFloat(item.customUnitPrice).toFixed(2)}`, itemPrice: `${res.invoice.currency} ${parseFloat(item.customItemPrice).toFixed(2)}`,
            })
          })
          this.isLoading = false
        })
    },
    edit() {
      this.$router.push(`/custom-invoices/${this.$route.params.id}/edit`)
    },
    async download() {
      this.downloading = true
      const url = `/api/custom-invoices/download/${this.$route.params.id}`
      await axios.get(url, { responseType: 'blob' })
        .then(res => {
          const pdfFilename = this.removeDot(this.pdfFilename)
          const link = document.createElement('a')
          const pdf_url = URL.createObjectURL(res.data)
          link.setAttribute('href', pdf_url)
          link.setAttribute('download', pdfFilename)
          link.style.visibility = 'hidden'
          link.id = 'downloadPDF'
          document.body.appendChild(link)
          document.getElementById(link.id).click()

          // remove to make sure a new csv file will be downloaded and not the previous one
          const element = document.getElementById(link.id)
          element.remove()
        })
      this.downloading = false
    },

    removeDot(str) {
      const str_array = str.split('.')
      let filename = ''
      str_array.forEach(x => {
        filename += x
      })
      return filename
    },

    viewUser() {
      this.$router.push(`/user/details/${this.userId}`)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.invoice-details-logo {
  width: 100%;
  max-width: 25rem;
}

.invoice-to-address {
  max-width: 20rem;
  line-height: 24px;
}

.invoice-period {
  padding: 10px 29px;
  border-top: 1px solid #e7e7e7;
  font-weight: 500;
  font-size: 14px;
  margin-top: -1rem;
}

.paid-style {
  color: #189550;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.vs__dropdown-toggle {
  border: 1px solid #d8d6de !important;
}

@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
